<template>
  <div class="m-2">
    <h1>Language tool</h1>
    <translation-level
      v-for="(value, attr) in translationTree"
      :key="attr"
      :attr="attr"
      :value="value"
      :secret="secret"
    />
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";
import ResourceService from "@/services/base/resource.service";
import TranslationLevel from "./TranslationLevel.vue";
export default {
  components: {
    BCard,
    TranslationLevel,
  },
  data() {
    return {
      secret: "",
      translationItems: [],
    };
  },
  async created() {
    this.secret = this.$route.params.secret;
    const service = new ResourceService("translations/items");
    this.translationItems = (await service.get(`s/${this.secret}`)).data.data;
  },
  computed: {
    translationTree() {
      const tree = {};

      for (const item of this.translationItems) {
        const keys = item.key.split(".");
        let currentNode = tree;

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (!currentNode[key]) {
            currentNode[key] = {};
          }
          currentNode = currentNode[key];

          if (i === keys.length - 1) {
            if (!currentNode.rows) {
              currentNode.rows = [];
            }
            const row = { key: keys[keys.length - 1], value: item.value };
            for (const attribute in item) {
              if (attribute !== "key" && attribute !== "value") {
                row[attribute] = item[attribute];
              }
            }
            currentNode.rows.push(row);
          }
        }
      }

      return tree;
    },
  },
};
</script>