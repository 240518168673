<template>
  <b-card>
    <div
      class="d-flex justify-content-between align-items-center namespace-head"
      @click="expanded = !expanded"
    >
      <h4 class="m-0">{{ attr }}</h4>
      <feather-icon v-if="expanded" icon="ChevronUpIcon" size="30" />
      <feather-icon v-else icon="ChevronDownIcon" size="30" />
    </div>
    <template v-if="expanded">
      <b-table-simple v-if="rowsAtLevel.length > 0" small class="mt-1">
        <b-thead>
          <b-th style="width: 300px">Key</b-th>
          <b-th
            v-for="(langInput, index) in langInputs(rowsAtLevel[0])"
            :key="`th${index}`"
          >
            {{ langInput }}
          </b-th>
        </b-thead>
        <b-tbody>
          <b-tr v-for="row in rowsAtLevel" :key="row.id">
            <b-td
              ><small>{{ row.key }}</small></b-td
            >
            <b-td
              v-for="(langInput, index) in langInputs(rowsAtLevel[0])"
              :key="`td${index}${row.Id}`"
            >
              <b-form-input v-model="row[langInput]" size="sm" @change="updateTranslationItem(row)" />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <template>
        <hr />
        <div class="m-2">
          <translation-level
            class="border"
            v-for="(value, attr) in itemsWithNestedLevels"
            :key="attr"
            :attr="attr"
            :value="value['value']"
            :secret="secret"
          />
        </div>
      </template>
    </template>
  </b-card>
</template>

<script>
import {
  BCard,
  BTableSimple,
  BThead,
  BTh,
  BTbody,
  BFormInput,
  BTr,
  BTd,
} from "bootstrap-vue";
import ResourceService from '@/services/base/resource.service';

export default {
  props: ["attr", "value", "secret"],
  components: {
    BTableSimple,
    BCard,
    BTbody,
    BTr,
    BTd,
    BThead,
    BTh,
    BFormInput,
    "translation-level": () => import("./TranslationLevel.vue"),
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    rowsAtLevel() {
      let result = [];
      for (const key in this.value) {
        if (key == "rows") {
          result = result.concat(this.value[key]);
        } else if (this.value[key] && this.value[key]["rows"]) {
          result = result.concat(this.value[key]["rows"]);
        }
      }
      return result;
    },
    itemsAtLevel() {
      let result = [];
      for (const key in this.items) {
        if (key != "children") {
          result.push({ key: key, row: this.items[key] });
        }
      }
      return result;
    },
    itemsWithNestedLevels() {
        let result = {}
        for (const key in this.value) 
        {
            if(Object.keys(this.value[key]).filter(i => i != "rows").length > 0)
            {

                let valueRes = this.value[key]
                delete valueRes.rows
                result[key] = {key: key, value: valueRes}
            }
        }
       return result;
    },
  },
  methods: {
    langInputs(item) {
      let result = [];
      for (const key in item) {
        if (key.includes("_Text")) result.push(key);
      }
      return result;
    },
    async updateTranslationItem(item)
    {
        const service = new ResourceService("translations/items");
        await service.post(`${item.id}/s/${this.secret}`, item);
    }
  },
};
</script>

<style lang="scss" scoped>
.namespace-head {
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
}
</style>